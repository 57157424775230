.SideMenuContainer {
    padding: 0px 24px 0px 20px;

    .SideMenu {
        border-radius: 16px;
        width: 100%;
        background: linear-gradient(180deg, rgba(249, 249, 249, 0.15) 35%, rgba(249, 249, 249, 0.03) 100%);
        height: calc(100vh - 246px);
        padding: 32px 24px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
    }
    
    .hoverSection:hover {
        background: #4F5052;
        border-radius: 16px;
    }
    .hoverSection{
        margin: 8px 0px;
        z-index: 1;
        cursor: pointer;
    }

    .titleSection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        

        .nameAndIcon {
            display: flex;
            .iconMenu {
                margin-right: 16px;
            }

            .titleMenu {
                color: #F9F9F9;
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                word-wrap: break-word
            }
        }

    }
    
    
    .arrowDownContainer
    {
        z-index: 5;
        .arrowDown {
            cursor: pointer;
            
        }
    }
    .subMenu    
    {
        margin-left: 30px;


        .subMenuContainer
        {
            padding: 10px 16px;
            display: flex;
            align-items: center;
            border-left: 3px solid white;
            transition: all .5s;
            cursor: pointer;
            .sumMenuText
            {
                color: #F9F9F9;
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                word-wrap: break-word
            }
        }
    }
    .backgroundEx
    {
        background: #616264;
        border-radius: 16px;
    }
    .selectedSubmenu
    {
        border-left: 4px solid #99000F!important;
        transition: all .5s;
    }
    .hrMenu
    {
        height: 1px;
        width: 100%;
        background:#545456;
        margin-top: 16px;
    }
  .img180
  {
    transform: scaleY(-1);
  }

}