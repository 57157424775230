.input-wrapper {
    position: relative;
    width: 100%;
    .input {
        box-sizing: border-box;
        color: white;
        padding: 15px 15px 15px 55px;
        width: 100%;
        background: rgba($color: #f9f9f9, $alpha: 0.10);
        border: none;
        border-radius: 16px;
        height: 48px;
        outline: none;
        font-size: 16px;
      }
      .input-icon {
        cursor: pointer;
        color: #A0A0A2;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        
      }
  }
  
 