body {
  margin: 0px;
  background: #1F2023;
}

.app {
  .structure {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100vh;
  }

  .headerPosition {
    grid-area: 1 / 1 / 2 / 13;
  }

  .sidebarPosition {
    grid-area: 2 / 1 / 8 / 3;
  }

  .content {
    grid-area: 2 / 3 / 8 / 11;
    margin-left: 45px;
  }

  @media screen and (max-width:1364px) {
    .structure {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-template-rows: repeat(7, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      height: 100vh;
    }

    .headerPosition {
      grid-area: 1 / 1 / 2 / 13;
    }

    .sidebarPosition {
      grid-area: 2 / 1 / 6 / 4;
    }

    .content {
      grid-area: 2 / 4 / 6 / 13;
      margin-left: 45px;
    }
  }

  // @media screen and (max-width:1024px) {
  //   .structure {
  //     display: grid;
  //     grid-template-columns: repeat(12, 1fr);
  //     grid-template-rows: repeat(5, 1fr);
  //     grid-column-gap: 0px;
  //     grid-row-gap: 0px;
  //   }

  //   .headerPosition {
  //     grid-area: 1 / 1 / 2 / 13;
  //   }

  //   .sidebarPosition {
  //     grid-area: 2 / 1 / 6 / 5;
  //   }

  //   .content {
  //     grid-area: 2 / 5 / 6 / 13;
  //     margin-left: 45px;
  //   }
  // }
}

/* Habilitar el scroll suave (si es compatible) */
html {
  scroll-behavior: smooth;
}

/* Deshabilitar el scroll suave */
html {
  scroll-behavior: auto;
}

/* Personalizar la barra de desplazamiento en WebKit */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}