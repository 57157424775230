header {
    padding: 16px;

    .headerContainer {
        padding: 24px 32px;
        background: linear-gradient(180deg, rgba(249, 249, 249, 0.15) 35%, rgba(249, 249, 249, 0.03) 100%);
        border-radius: 16px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .headerLogo {
            grid-area: 1 / 1 / 2 / 2;
            
            img {
                cursor: pointer;
                width: 226px;
            }
        }

        .searchEnginePosition {
            grid-area: 1 / 2 / 2 / 3;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 32px;

            .searchEngine {
                width: 480px;
                @media screen and (max-width:1200px) {
                    width: 295px;
                  }

            }

            .notificationIcon {
                cursor: pointer;

            }

            .nameContainer {
                display: flex;
                font-size: 16px;
                color: #F9F9F9;
                width: 200px;
                align-items: center;

                .name {
                    margin-right: 8px;
                    min-width: 145px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                img {
                    cursor: pointer;
                }
            }

            .avatar {
                width: 48px;
                height: 48px;
                border-radius: 100%;
                object-fit: cover;
                cursor: pointer;

            }
        }
    }
}